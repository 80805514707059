import React from 'react'
import { Link } from 'gatsby'
import Button from '../../Button/Button'
const ServiceCTA = ({ title, ctaText, ctaLink }) => {
    return (
        <div className="title-cta text-center bg-[#2B334B] px-7 py-6 mx-auto w-[90%] rounded-2xl md:py-10 md:px-[100px] lg:max-w-[993px]">
            <h3 className="text-white [text-wrap:balance] text-lg sm:text-2xl md:text-3xl lg:text-[32px] mb-6 md:mb-10 lg:mb-[33px] md:leading-10">
                {title}
            </h3>
            <div className="cta flex-shrink-0 flex-grow-0">
                <Link
                    to={ctaLink}
                >
                    <Button type="white">{ctaText}</Button>
                </Link>
            </div>
        </div>
    )
}

ServiceCTA.defaultProps = {
    title: "Get in touch with us today to see how we can elevate your next project.",
    ctaText: "Schedule a consultation",
    ctaLink: "/contact/",
}


export default ServiceCTA

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Wave from '../images/wave.png'
import LineAnimation from './LineAnimation'
import './process.scss'
import ProgressLine1 from './Line/ProgressLine1'
import ProgressLine2 from './Line/ProgressLine2'
import ProgressLine3 from './Line/ProgressLine3'
import RadarIcon from './Icon/RadarIcon'
import DrawingTableIcon from './Icon/DrawingTableIcon'
import USBIcon from './Icon/USBIcon'
import SettingIcon from './Icon/SettingIcon'
import SEOIcon from './Icon/SEOIcon'
import IconWrapper from './Icon/IconWrapper'
const Process = () => {
    return (
        <>
            <section className="relative py-20 bg-[#1D2333] overflow-hidden">
                <div className="container mx-auto">
                    <div className="title-container md:max-w-[730px] flex flex-col text-center mx-auto">
                        <h5 className="pre-header">HOW WE DO IT</h5>
                        <h2 className="font-bold text-3xl leading-9 sm:text-4xl md:text-5xl md:leading-[68px] text-white">
                            Our Process
                        </h2>
                    </div>
                </div>
                <div className="process grid grid-cols-1 mt-14 md:mt-[103px] max-w-[400px] sm:max-w-[640px] md:max-w-[768px] lg:max-w-[937px] mx-auto">
                    <div
                        className="process-block grid grid-cols-8 col-start-2 col-span-10 sm:col-[4_/_12] row-[1_/_8]
                                md:col-[3_/_11] lg:col-[2_/_10] lg:pl-4"
                    >
                        <div className="col-start-4 col-span-4 row-start-1 w-24 row-span-3 relative md:w-[204px] md:col-start-5 lg:w-[255px] lg:col-start-7">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/process1.svg"
                            />

                            <div className="absolute top-0 right-0 w-[65%]">
                                <IconWrapper>
                                    <RadarIcon />
                                </IconWrapper>
                            </div>
                        </div>
                        <div className="col-start-1 row-start-3 row-span-2 max-w-[68px] md:w-[54px] lg:w-[68px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/processNumber1.svg"
                            />
                        </div>
                        <div className="process-text col-start-2 row-start-4 col-span-4 z-10 pl-[10px] w-[200px] md:w-[300px] lg:w-[350px] md:row-start-6 lg:row-start-7 lg:col-start-3">
                            <h4 className="font-bold text-white leading-8 text-lg sm:text-xl md:leading-10 md:text-3xl md:max-w-[269px]">
                                Discover
                            </h4>
                            <p className="text-white text-xs text-left md:text-sm lg:text-lg md:text-justify md:mt-1 lg:mt-[38px] md:leading-5 lg:leading-9 tracking-[-0.025rem]">
                                We dig deep into your needs to discover your
                                unique challenges and find out what success
                                looks like for your business.
                            </p>
                        </div>
                    </div>
                    <LineAnimation
                        styleClass={`relative row-start-4 col-[2_/_6] sm:row-[4_/_7] sm:col-[3_/_10] md:col-[2_/_10] lg:col-[1_/_13] lg:row-[5_/_8] max-w-[470px]`}
                    >
                        <ProgressLine1 />
                    </LineAnimation>
                    <div
                        className="process-block grid grid-cols-8 col-start-2 col-span-8 row-[7_/_15]
                                sm:col-start-10 sm:row-[7_/_14] md:col-start-10 lg:col-[13_/_21] lg:row-[9_/_17] sm:pl-2 md:pl-4 md:pt-4 lg:pt-0"
                    >
                        <div className="relative col-start-4 col-span-4 row-start-1 w-24 row-span-4 max-w-[271px] md:w-[216px] md:col-start-5 lg:w-[271px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/process2.svg"
                            />

                            <div className="absolute top-0 right-0 w-[65%]">
                                <IconWrapper>
                                    <DrawingTableIcon />
                                </IconWrapper>
                            </div>
                        </div>
                        <div className="col-start-1 row-start-4 row-span-2 sm:row-start-3 lg:row-start-4 md:w-[84px] lg:[105px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/processNumber2.svg"
                            />
                        </div>
                        <div className="process-text col-start-2 row-start-4 col-span-4 z-10 pl-[10px] md:pl-0 w-[200px] md:w-[300px] lg:w-[350px] md:col-start-3 md:row-start-6">
                            <h4 className="font-bold text-white leading-8 text-lg sm:text-xl md:leading-10 md:text-3xl md:max-w-[269px]">
                                Define
                            </h4>
                            <p className="text-white text-xs text-left md:text-sm lg:text-lg md:text-justify md:mt-1 lg:mt-[38px] md:leading-5 lg:leading-9 tracking-[-0.025rem]">
                                We define a product and technology strategy
                                which shapes our prototype and roadmap.
                            </p>
                        </div>
                    </div>
                    <LineAnimation styleClass="col-start-3 row-[11_/_14] col-span-4 sm:row-[9_/_12] sm:col-[3_/_10] md:col-[3_/_11] md:row-[11_/_13] lg:row-[13_/_16] lg:col-[3_/_15] lg:pt-7 max-w-[441px]">
                        <ProgressLine2 />
                    </LineAnimation>
                    <div
                        className="process-block grid grid-cols-8 col-start-2 col-span-8 row-[13_/_20]
                                sm:col-start-3 sm:row-[11_/_19] md:col-start-2 md:row-[13_/_23] md:pt-5 lg:row-[17_/_27] lg:pt-8"
                    >
                        <div className="relative col-start-4 col-span-4 row-start-1 w-24 row-span-3 md:w-[228px] md:col-start-5 md:mt-5 lg:-[286px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/process3.svg"
                            />

                            <div className="absolute top-0 right-0 w-[65%]">
                                <IconWrapper>
                                    <USBIcon />
                                </IconWrapper>
                            </div>
                        </div>
                        <div className="col-start-1 row-start-4 row-span-2 md:w-[84px] lg:w-[105px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/processNumber3.svg"
                            />
                        </div>
                        <div className="process-text col-start-2 row-start-4 col-span-4 z-10 pl-[10px] w-[200px] md:pl-0 lg:pl-[10px] md:w-[300px] lg:w-[350px] md:col-start-3 md:row-start-6">
                            <h4 className="font-bold text-white leading-8 text-lg sm:text-xl md:leading-10 md:text-3xl md:max-w-[269px]">
                                Develop
                            </h4>
                            <p className="text-white text-xs text-left md:text-sm lg:text-lg md:text-justify md:mt-1 lg:mt-[38px] md:leading-5 lg:leading-9 tracking-[-0.025rem]">
                                This is where the fun begins: using data and
                                insights, we design, develop, and bring your
                                digital solution to life.
                            </p>
                        </div>
                    </div>
                    <LineAnimation
                        styleClass="col-start-2 row-[17_/_20] col-span-4 sm:row-[14_/_18] sm:col-[2_/_9]
                                md:col-[2_/_10] md:row-[17_/_24] md:pt-5 lg:row-[22_/_29] lg:col-[1_/_13] lg:pl-4 max-w-[475px]"
                    >
                        <ProgressLine3 />
                    </LineAnimation>
                    <div
                        className="process-block grid grid-cols-8 col-start-2 col-span-8 row-[20_/_27]
                                sm:col-start-9 sm:row-[15_/_22] md:col-[10_/_18] md:row-[18_/_27] sm:pl-2 md:pl-5 lg:row-[25_/_34] lg:col-[13_/_22]"
                    >
                        <div className="relative col-start-4 col-span-4 row-start-2 w-24 md:row-start-1 row-span-3 md:w-[208px] md:col-start-4 lg:w-[261px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/process4.svg"
                            />

                            <div className="absolute top-0 right-0 w-full translate-x-1/4">
                                <IconWrapper>
                                    <SettingIcon />
                                </IconWrapper>
                            </div>
                        </div>
                        <div className="col-start-1 row-start-5 row-span-2 md:w-[84px] lg:w-[105px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/processNumber4.svg"
                            />
                        </div>
                        <div className="process-text col-start-2 row-start-5 col-span-4 z-10 pl-[10px] md:pl-5 w-[200px] md:w-[300px] lg:w-[350px] md:col-start-2 md:row-start-6 lg:pl-7">
                            <h4 className="font-bold text-white leading-8 text-lg sm:text-xl md:leading-10 md:text-3xl md:max-w-[269px]">
                                Refine
                            </h4>
                            <p className="text-white text-xs text-left md:text-sm lg:text-lg md:text-justify md:mt-1 lg:mt-[38px] md:leading-5 lg:leading-9 tracking-[-0.025rem]">
                                We test our products on real users to ensure our
                                tech works seamlessly.
                            </p>
                        </div>
                    </div>
                    <LineAnimation styleClass="col-start-3 row-[24_/_27] col-span-4 sm:row-[18_/_22] sm:col-[3_/_10] md:col-[4_/_12] md:row-[22_/_26] lg:row-[30_/_33] lg:col-[3_/_15] lg:pt-7 max-w-[441px] sm:pt-3 sm:pr-3 md:pt-5">
                        <ProgressLine2 />
                    </LineAnimation>
                    <div
                        className="process-block grid grid-cols-8 col-start-2 col-span-8 sm:col-[2_/_10] row-[25_/_29] sm:row-[21_/_25]
                                md:col-[3_/_11] md:row-[24_/_29] lg:row-[33_/_42] lg:col-[1_/_9] pt-4 lg:pl-4 lg:pt-6"
                    >
                        <div className="relative col-start-4 col-span-4 row-start-1 w-24 row-span-3 md:w-[228px] md:col-start-6  md:mt-5 lg:w-[286px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/process5.svg"
                            />

                            <div className="absolute top-0 right-0 w-[65%]">
                                <IconWrapper>
                                    <SEOIcon />
                                </IconWrapper>
                            </div>
                        </div>
                        <div className="col-start-1 row-start-3 md:row-start-5 lg:row-start-6 row-span-2 md:pl-2 md:w-[84px] lg:w-[105px]">
                            <StaticImage
                                layout="fullWidth"
                                src="../images/processNumber5.svg"
                            />
                        </div>
                        <div className="process-text col-start-2 row-start-3 col-span-4 z-10 pl-[10px] w-[200px] md:pl-0 lg:pl-[10px] md:w-[300px] lg:w-[350px] md:col-start-3 md:row-start-6 md:pt-3 lg:pt-0 lg:row-[8_/_12]">
                            <h4 className="font-bold text-white leading-8 text-lg sm:text-xl md:leading-10 md:text-3xl md:max-w-[269px]">
                                Grow
                            </h4>
                            <p className="text-white text-xs text-left md:text-sm lg:text-lg md:text-justify md:mt-1 lg:mt-[38px] md:leading-5 lg:leading-9 tracking-[-0.025rem]">
                                We’re in it for the long haul! Our strategy and
                                ongoing maintenance continually optimises and
                                grows your products.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="wave absolute w-full bottom-0 translate-y-[99%]">
                    <img src={Wave} alt="wave image" />
                </div>
            </section>
        </>
    )
}

export default Process

import React from 'react'

const USBIcon = () => {
    return (
        <svg
            viewBox="0 0 217 217"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full"
        >
            <path
                d="M133.125 45.208C133.125 58.808 122.1 69.833 108.5 69.833C94.9 69.833 83.875 58.808 83.875 45.208C83.875 31.608 94.9 20.583 108.5 20.583C122.1 20.583 133.125 31.608 133.125 45.208Z"
                stroke="white"
                stroke-width="5"
                className="icon usb-1"
            />
            <path
                d="M78.875 81.375C78.875 94.975 67.85 106 54.25 106C40.65 106 29.625 94.975 29.625 81.375C29.625 67.775 40.65 56.75 54.25 56.75C67.85 56.75 78.875 67.775 78.875 81.375Z"
                stroke="white"
                stroke-width="5"
                className="icon usb-2"
            />
            <path
                d="M187.375 99.458C187.375 113.058 176.35 124.083 162.75 124.083C149.15 124.083 138.125 113.058 138.125 99.458C138.125 85.858 149.15 74.833 162.75 74.833C176.35 74.833 187.375 85.858 187.375 99.458Z"
                stroke="white"
                stroke-width="5"
                className="icon usb-3"
            />
            <path
                d="M162.749 126.583C159.661 126.554 156.603 125.991 153.707 124.92V138.148L117.54 150.209V70.6426C111.717 72.8611 105.281 72.8611 99.457 70.6426V136.791L63.2904 112.704V106.836C57.4667 109.055 51.0307 109.055 45.207 106.836V117.542C45.2068 119.03 45.5739 120.495 46.2758 121.807C46.9777 123.12 47.9926 124.238 49.2306 125.064L99.457 158.546V189.875C99.457 192.273 100.41 194.573 102.105 196.268C103.801 197.964 106.101 198.917 108.499 198.917C110.897 198.917 113.196 197.964 114.892 196.268C116.588 194.573 117.54 192.273 117.54 189.875V169.26L165.606 153.238C167.406 152.639 168.971 151.489 170.081 149.95C171.191 148.412 171.789 146.564 171.79 144.667V124.92C168.895 125.991 165.836 126.554 162.749 126.583Z"
                fill="none"
                stroke="#C3D3FF"
                className="icon usb-4"
                clipPath="url(#usb-4)"
                strokeWidth={50}
            />

            <defs>
                <clipPath id="usb-4">
                    <path d="M162.749 126.583C159.661 126.554 156.603 125.991 153.707 124.92V138.148L117.54 150.209V70.6426C111.717 72.8611 105.281 72.8611 99.457 70.6426V136.791L63.2904 112.704V106.836C57.4667 109.055 51.0307 109.055 45.207 106.836V117.542C45.2068 119.03 45.5739 120.495 46.2758 121.807C46.9777 123.12 47.9926 124.238 49.2306 125.064L99.457 158.546V189.875C99.457 192.273 100.41 194.573 102.105 196.268C103.801 197.964 106.101 198.917 108.499 198.917C110.897 198.917 113.196 197.964 114.892 196.268C116.588 194.573 117.54 192.273 117.54 189.875V169.26L165.606 153.238C167.406 152.639 168.971 151.489 170.081 149.95C171.191 148.412 171.789 146.564 171.79 144.667V124.92C168.895 125.991 165.836 126.554 162.749 126.583Z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default USBIcon

import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./iconCard.scss"

const IconCard = ({ title, text, link, children }) => {
    return (
        <Link
            className="service-card group isolate w-full rounded-[43px] border-2 border-solid border-[#1D2333] px-7 py-6 lg:px-9 lg:py-10"
            to={`/${link}`}
        >
            <div className="card-icon absolute right-7 top-7 z-[-1] max-w-[80px] lg:max-w-[100px] ">
                {children}
            </div>

            <h4 className="card-title relative w-[70%] text-lg font-bold !leading-tight text-[#1D2333] md:text-2xl lg:text-[32px] min-h-[80px]">
                {title}
            </h4>
            <p className="card-text mt-[15px] max-w-[500px] place-self-end text-xs leading-6 tracking-[-0.025rem] text-white/[0.8] sm:mt-[5px] md:mt-[15px] lg:text-lg lg:leading-9">
                {text}
            </p>

            <div className="card-link group z-30 mt-10 flex items-center gap-x-2 place-self-center justify-self-start text-xs font-medium uppercase leading-9 tracking-[0.2em] text-white sm:mt-[40px] md:mt-[10px] lg:mt-[42px] lg:text-sm">
                Learn more
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 227.096 227.096"
                    style={{ enableBackground: "new 0 0 227.096 227.096" }}
                    xmlSpace="preserve"
                    className="card-link-icon h-4 w-4 transition-all duration-[0.4s]"
                    fill="#FFF"
                    path="#FFF"
                >
                    <polygon
                        points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 
			146.933,181.902 152.835,187.811 227.096,113.55"
                    />
                </svg>
            </div>
            <div className="shape">
                <StaticImage src="../images/card-wave.svg" />
            </div>
        </Link>
    )
}

export default IconCard

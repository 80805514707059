import React from 'react'
import Hero from '../../components/ServiceLanding/Hero/Hero'
import IconCardBlock from '../../components/ServiceLanding/IconCard/IconCardBlock'
import Layout from '../../components/layout'
import ServiceCTA from '../../components/ServiceLanding/CTA/ServiceCTA'
import data from '../../data/data.json'
import Testimonial from '../../components/Testimonial/Testimonial'
import Process from './../../components/ServiceLanding/Process/Process'
import { SEO } from '../../components/seo'
const Services = ({location}) => {
    const testimonial = data.testimonials[0]
    const context = {
        pageName: "SD | Services",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <Hero />
            <IconCardBlock />
            <Process />
            <div className="py-10 lg:py-28 bg-dark-gunmetal">
                <ServiceCTA
                    title={
                        'Get in touch with us today to see how we can elevate your next project.'
                    }
                />
            </div>
            <div className="pt-10 pb-8 md:pt-[109px] md:pb-[54px] bg-dark-gunmetal">
                <Testimonial props={testimonial} />
            </div>
        </Layout>
    )
}

export default Services
export const Head = () => <SEO title={'Our Services | Saigon Digital'} description={"Bespoke, beautifully crafted development services."}/>

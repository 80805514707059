import React from 'react'
function ProgressLine2() {
    return (
        <svg
            width="452"
            height="272"
            viewBox="0 0 452 272"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className='w-[170px] h-[110px] rotate-[-30deg] sm:h-[200px] sm:w-[320px] sm:rotate-0 md:w-auto lg:h-auto'
        >
            <defs>
                <path
                    id="c-dashed-line-2"
                    d="M446.591 5.8125C367.374 25.779 99.8496 68.6187 5.49609 266.342"
                />
            </defs>
            <use class="c-dashed-line__path" href="#c-dashed-line-2" />
            <use class="c-dashed-line__dash" href="#c-dashed-line-2" />
        </svg>
    )
}

export default ProgressLine2

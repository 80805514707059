import React from 'react'
function ProgressLine1() {
    return (
        <svg
            width="478"
            height="369"
            viewBox="0 0 478 369"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-[165px] rotate-[27deg] sm:w-auto sm:h-[220px] sm:rotate-0 md:h-[280px] lg:h-auto "
        >
            <defs>
                <path
                    id="c-dashed-line"
                    d="M61.541 5.71619C-34.0089 124.779 -85.5889 362.906 472.49 362.906"
                />
            </defs>
            <use class="c-dashed-line__path" href="#c-dashed-line" />
            <use class="c-dashed-line__dash" href="#c-dashed-line" />
        </svg>
    )
}

export default ProgressLine1

import React, { useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'

const IconWrapper = ({ children }) => {
    const ref = useRef(null)
    const { inViewport } = useInViewport(ref)

    useEffect(() => {
        if (inViewport) {
            ref.current.classList.add('go')
        }
    }, [inViewport])
    return (
        <div className="w-full" ref={ref}>
            {children}
        </div>
    )
}

export default IconWrapper

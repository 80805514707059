import React from 'react'
import Blob from './Blob'

const Hero = () => {
    return (
        <section className="h-[calc(100vh-91px)] grid items-center justify-center relative isolate lg:h-[calc(100vh-160px)] overflow-x-clip">
            <div className="absolute z-[-1] top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2 max-w-lg lg:max-w-7xl lg:max-h-full lg:-translate-y-0 lg:top-[-35%] lg:translate-x-0">
                <Blob />
            </div>

            <div className="container">
                <div className="grid">
                    <div className="grid grid-cols-1 md:grid-cols-12 place-items-center">
                        <div className="md:w-full md:col-span-6 md:col-start-1 max-w-[600px] text-center md:text-left lg:col-start-1">
                            <h1 className="font-bold page-title leading-tight max-w-xl">
                                Bespoke, beautifully crafted development
                                services.
                            </h1>
                        </div>
                        <div className="mt-8 md:mt-[0] md:col-start-8 md:col-span-5 z-[2] max-w-[400px]">
                            <p className="w-5/6 md:w-full sm:text-left text-center mx-auto text-sm md:text-lg md:leading-9 text-[#1D2333]/[0.8] tracking-[-0.025rem]">
                                Saigon Digital is a tech focused creative
                                agency, with a special interest in delivering
                                fast, flexible and user centric digital
                                solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero

import React from 'react'

const SEOIcon = () => {
    return (
        <svg
            viewBox="0 0 210 210"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full"
        >
            <path
                d="M112.954 147.954L112.969 147.939L112.984 147.923C115.034 145.801 116.168 142.959 116.142 140.01C116.116 137.06 114.933 134.238 112.847 132.153C110.762 130.067 107.94 128.884 104.99 128.858C102.041 128.832 99.1987 129.966 97.077 132.016L97.0613 132.031L97.046 132.046L86.0701 143.022L67.4932 130.64C67.4931 130.64 67.4931 130.64 67.493 130.64C67.4929 130.64 67.4929 130.64 67.4928 130.64C65.3287 129.197 62.7314 128.548 60.143 128.805C57.5546 129.061 55.135 130.207 53.296 132.046C53.2959 132.046 53.2958 132.046 53.2957 132.046L28.75 156.592V81.25H181.25V175C181.25 176.658 180.592 178.247 179.419 179.419C178.247 180.592 176.658 181.25 175 181.25H35.908L62.6799 154.478L81.2568 166.86C81.2569 166.86 81.2569 166.86 81.257 166.86C81.2571 166.86 81.2571 166.86 81.2572 166.86C83.4213 168.303 86.0186 168.952 88.607 168.695C91.1954 168.439 93.615 167.294 95.454 165.454C95.4541 165.454 95.4542 165.454 95.4543 165.454L112.954 147.954ZM123.296 121.704L123.296 121.704C125.406 123.813 128.267 124.998 131.25 124.998C134.233 124.998 137.094 123.813 139.204 121.704L139.204 121.704L147.954 112.954L147.969 112.939L147.984 112.923C150.034 110.801 151.168 107.959 151.142 105.01C151.116 102.06 149.933 99.2384 147.847 97.1526C145.762 95.0667 142.94 93.8836 139.99 93.8579C137.041 93.8323 134.199 94.9663 132.077 97.0155L132.061 97.0306L132.046 97.046L123.296 105.796L123.296 105.796C121.187 107.906 120.002 110.767 120.002 113.75C120.002 116.733 121.187 119.594 123.296 121.704Z"
                stroke="white"
                stroke-width="5"
                className="icon seo-1"
            />
            <path
                d="M181.25 35V58.75H28.75V35C28.75 33.3424 29.4085 31.7527 30.5806 30.5806C31.7527 29.4085 33.3424 28.75 35 28.75H175C176.658 28.75 178.247 29.4085 179.419 30.5806C180.592 31.7527 181.25 33.3424 181.25 35Z"
                stroke="#D9E3FF"
                stroke-width="5"
                className="icon seo-2"
            />
        </svg>
    )
}

export default SEOIcon

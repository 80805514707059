import React from 'react'

const RadarIcon = () => {
    return (
        <svg
            viewBox="0 0 195 195"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full"
        >
            <path
                d="M73.2243 124.935C79.9196 130.864 88.5553 134.133 97.4981 134.125C104.706 134.137 111.756 132.019 117.764 128.037C123.773 124.054 128.47 118.384 131.266 111.739C134.062 105.095 134.831 97.7717 133.478 90.6911C132.189 83.9503 129.031 77.7152 124.374 72.6931V59.9291C131.959 65.3516 137.759 72.9648 140.96 81.7963C144.669 92.0324 144.648 103.249 140.901 113.471C137.154 123.694 129.921 132.266 120.475 137.68C111.029 143.094 99.9755 145.002 89.2612 143.068C78.5469 141.134 68.8583 135.482 61.9011 127.108C54.9439 118.733 51.1642 108.172 51.2273 97.2852C51.2905 86.3979 55.1924 75.8819 62.2463 67.5886C68.6559 60.0526 77.2947 54.7909 86.8741 52.535V62.5565C78.9825 64.9445 72.1083 69.9378 67.3949 76.7497C62.3057 84.1047 60.0885 93.0697 61.1627 101.949C62.2369 110.828 66.528 119.006 73.2243 124.935Z"
                stroke="white"
                stroke-width="5"
                className="radar-1 icon"
            />
            <path
                d="M157.891 67.2627C150.769 53.0898 138.896 41.8919 124.373 35.6048V23.4801C141.567 29.7196 156.091 41.7679 165.397 57.5977C175.283 74.4153 178.575 94.3038 174.635 113.41C170.695 132.516 159.804 149.48 144.071 161.015C128.339 172.55 108.885 177.835 89.478 175.845C70.0715 173.856 52.094 164.735 39.0286 150.248C25.9631 135.761 18.7397 116.941 18.7578 97.4326C18.776 77.9244 26.0343 59.1174 39.1267 44.655C51.5985 30.8782 68.5186 21.9782 86.8734 19.4771V30.8931C71.4252 33.3635 57.2873 41.1269 46.904 52.8899C36.0329 65.2056 30.023 81.0612 29.9992 97.4885C29.9755 113.916 35.9395 129.789 46.775 142.136C57.6104 154.483 72.5744 162.458 88.8657 164.568C105.157 166.677 121.659 162.777 135.282 153.598C148.905 144.418 158.716 130.588 162.877 114.696C167.039 98.8048 165.266 81.9413 157.891 67.2627Z"
                stroke="white"
                stroke-width="5"
                className="radar-2 icon"
            />
            <path
                d="M89.375 83.5006C86.2771 85.2892 83.8559 88.0501 82.487 91.3549C81.118 94.6598 80.8779 98.3241 81.8037 101.779C82.7296 105.235 84.7697 108.288 87.6076 110.466C90.4456 112.643 93.9228 113.824 97.5 113.824C101.077 113.824 104.554 112.643 107.392 110.466C110.23 108.288 112.27 105.235 113.196 101.779C114.122 98.3241 113.882 94.6598 112.513 91.3549C111.144 88.0501 108.723 85.2892 105.625 83.5006V16.6644C100.222 16.1119 94.7776 16.1119 89.375 16.6644V83.5006Z"
                fill="none"
                stroke="#BECFFF"
                strokeWidth={50}
                className="radar-3 icon"
                clipPath="url(#radar-3)"
            />

            <defs>
                <clipPath id="radar-3">
                    <path d="M89.375 83.5006C86.2771 85.2892 83.8559 88.0501 82.487 91.3549C81.118 94.6598 80.8779 98.3241 81.8037 101.779C82.7296 105.235 84.7697 108.288 87.6076 110.466C90.4456 112.643 93.9228 113.824 97.5 113.824C101.077 113.824 104.554 112.643 107.392 110.466C110.23 108.288 112.27 105.235 113.196 101.779C114.122 98.3241 113.882 94.6598 112.513 91.3549C111.144 88.0501 108.723 85.2892 105.625 83.5006V16.6644C100.222 16.1119 94.7776 16.1119 89.375 16.6644V83.5006Z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default RadarIcon

import React from "react"
import { Link } from "gatsby"
import IconCard from "./IconCard"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../../Button/Button"

const IconCardBlock = () => {
    return (
        <section className="relative z-10 py-20">
            <div className="container mx-auto max-w-[980px]">
                <div className="mx-auto mb-16 flex flex-col items-center md:mb-20 md:flex-row md:items-start md:justify-around lg:justify-start ">
                    <div className="title-container flex flex-col text-center md:max-w-[475px] md:text-left lg:mr-[276px]">
                        <h5 className="pre-header md:!text-left">What we do</h5>
                        <h2 className="text-3xl font-bold leading-9 text-[#27282A] sm:text-4xl md:text-5xl md:leading-[68px]">
                            View our range of services below...
                        </h2>
                    </div>
                    <div className="cta flex-shrink-0 flex-grow-0">
                        <Link
                            to="/our-work"
                            className="mx-auto mt-5 md:mt-[62px]"
                        >
                            <Button type="gray">View our work</Button>
                        </Link>
                    </div>
                </div>
                <div className="mt-6 grid justify-center justify-items-center gap-[20px] md:mt-[42px] md:grid-cols-2 lg:gap-[50px]">
                    <IconCard
                        title="Professional Web Development Services"
                        text="Saigon Digital's web developers focus on client needs, creating impactful website solutions and guiding brands through the evolving digital landscape."
                        link="services/professional-web-development/"
                    >
                        <StaticImage
                            src="../images/wordpress-icon.svg"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="Professional Web Design Services"
                        text="Enhance your brand with our custom graphic designs that combine striking visuals and smooth functionality, ensuring captivating digital experiences that engage your audience and boost conversions."
                        link="services/professional-web-design/"
                    >
                        <StaticImage
                            src="../images/ui-ux-design.png"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="Digital Marketing"
                        text="We are Saigon Digital, a team of experts who take a personalized approach to each service we offer, ensuring we understand your unique business needs and goals. Whether you're looking to increase website traffic, generate leads, establish a strong brand identity, or create engaging content, we have the expertise to help you succeed."
                        link="services/digital-marketing/"
                    >
                        <StaticImage
                            src="../images/digital-marketing-icon.svg"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                        <StaticImage
                            src="../images/cloud-icon.svg"
                            className="override-logo h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="SEO Services"
                        text="With our SEO services, we optimise your website to increase organic traffic and improve search rankings. Using the latest strategies, we help you attract more leads and dominate your industry's search results. Saigon Digital’s SEO services are your key to unlocking the full potential of your website."
                        link="services/seo/"
                    >
                        <StaticImage
                            src="../images/seo.png"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="Jamstack Development"
                        text="The future of the web is right here, get a blazing fast, SEO friendly and highly optimised and performant website on the jamstack. The possibilities are endless."
                        link="services/jamstack-development/"
                    >
                        <StaticImage
                            src="../images/jamstack-icon.svg"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="UI/UX Design"
                        text="In UI/UX Design, Saigon Digital focuses on crafting user-friendly interfaces and engaging experiences tailored to your audience's needs. Our goal is to enhance usability, drive conversions, and leave a lasting impression."
                        link="services/ui-ux-design/"
                    >
                        <StaticImage
                            src="../images/ui-ux-design.png"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>

                    <IconCard
                        title="Digital Transformation"
                        text="Are you ready to take your business to the next level? Stay ahead of your competitors and let us connect the dots, integrate your systems and develop solutions that are autonomous, scalable, tech focused and will keep you and your business future proofed and more efficient than ever."
                        link="services/digital-transformation/"
                    >
                        <StaticImage
                            src="../images/digital-transformation-icon.svg"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="Business Growth Accelerator"
                        text="We are thrilled to introduce the Business Growth Accelerator, an integrated solution by Saigon Digital designed to drive your business forward. This comprehensive package consists of two key components: the Site Audit and the SD Performance Dashboard."
                        link="services/business-growth-accelerator/"
                    >
                        <StaticImage
                            src="../images/Jamstack.png"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="Support Services"
                        text="Do you already have an existing website/application that you need some support with? We offer flexible and bespoke support services to help keep your website ticking along nicely. May it be a new feature, technical advice, improvement for UI/UX - we got you covered."
                        link="services/support-services/"
                    >
                        <StaticImage
                            src="../images/support-service-icon.svg"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="Headless e-Commerce"
                        text="Creating a successful e-Commerce solution is no easy feat, but having the right tools and developers at your disposal is one of the most crucial aspects. Get ahead of your competition with the latest technology - Headless e-Commerce on the Jamstack.                        "
                        link="services/headless-ecommerce/"
                    >
                        <StaticImage
                            src="../images/headlesscms-icon.svg"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="Hubspot CMS Development"
                        text="The Hubspot platform offers a variety of features to aid your marketing success, we can help you develop a website in the Hubspot CMS that generates opportunities and revenue for your business.                        "
                        link="services/hubspot-development/"
                    >
                        <StaticImage
                            src="../images/hubspot-icon.svg"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                    <IconCard
                        title="Bespoke Wordpress Development"
                        text="WordPress is one of the leading Content Management Systems on the web today. Our team love building bespoke, highly performant and user friendly WordPress websites. We focus on giving a tailored, rich and flexible content editing experience for you and your team."
                        link="services/wordpress-development/"
                    >
                        <StaticImage
                            src="../images/wordpress-icon.svg"
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    </IconCard>
                </div>
            </div>
        </section>
    )
}
export default IconCardBlock

import React, { useRef, useEffect, useState } from 'react'
import { useInViewport } from 'react-in-viewport'

const LineAnimation = ({ styleClass, children }) => {
    const ref = useRef()

    const { inViewport } = useInViewport(ref)
    const [animatedClass, setAnimatedClass] = useState('')

    useEffect(() => {
        if (inViewport) {
            setAnimatedClass('animated-path')
        }
    }, [inViewport])

    return (
        <div ref={ref} className={`${animatedClass} ${styleClass}`}>
            {children}
        </div>
    )
}

export default LineAnimation

import React from 'react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { StaticImage } from 'gatsby-plugin-image'
import './testimonial.scss'

const Testimonial = ({ props }) => {
    const { testimonial, person, logo } = props

    const swiperSettings = {
        pagination: {
            el: '.testimonial-pagination',
            type: 'bullets'
        },
        modules: [Pagination],
        centeredSlides: true
    }

    return (
        <div className="bg-container-bg-color">
            <div className="container mx-auto text-white relative">
                <img
                    src="/testimonial/quote-symbol.svg"
                    class="min-w-0 absolute left-0 -top-6 aspect-[68/57] w-3 hidden sm:block surface:w-5 md:w-16 md:-translate-y-full"
                />

                <img
                    src="/testimonial/quote-symbol.svg"
                    class="min-w-0 absolute right-0 top-[170px] -scale-x-[1] aspect-[68/57] w-3 hidden sm:block surface:w-5 md:w-16"
                />

                <Swiper
                    {...swiperSettings}
                    centeredSlidesBounds={true}
                    centeredSlides={true}
                    centerInsufficientSlides={true}
                    className="px-5"
                >
                    <SwiperSlide>
                        <div className="text-center">
                            <cite className="font-light flex justify-center items-center mx-auto text-[8px] min-h-[170px] mb-2 text-left !leading-loose md:max-w-[950px] md:text-xs lg:text-base md:mb-5">
                                {testimonial}
                            </cite>

                            <p className="pre-header py-5">
                                {person}
                            </p>
                            <StaticImage
                                width={100}
                                alt='OTM Create Testimonial' 
                                src="../../images/testimonial/otm-white-bg.png"
                            />
                        </div>
                    </SwiperSlide>
                    <div className="testimonial-pagination text-center md:mt-6 lg:mt-11"></div>
                </Swiper>
            </div>
        </div>
    )
}

export default Testimonial

import React from 'react'

const DrawingTableIcon = () => {
    return (
        <svg
            viewBox="0 0 206 206"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full"
        >
            <path
                d="M186.335 78.9552V163.084C186.335 166.973 184.789 170.704 182.039 173.455C179.288 176.205 175.558 177.75 171.668 177.75H34.3346C30.4448 177.75 26.7143 176.205 23.9637 173.455C21.2132 170.704 19.668 166.973 19.668 163.084V60.0837C19.668 56.1938 21.2132 52.4633 23.9637 49.7128C26.7143 46.9622 30.4448 45.417 34.3346 45.417H118.467L92.6505 71.2335C92.6504 71.2336 92.6503 71.2337 92.6502 71.2338C88.9619 74.9211 86.8893 79.9225 86.8881 85.1379V85.1384V108.03C86.8881 113.246 88.9602 118.248 92.6484 121.937C96.3366 125.625 101.339 127.697 106.555 127.697L129.441 127.697C129.442 127.697 129.443 127.697 129.444 127.697C132.034 127.702 134.599 127.196 136.993 126.208C139.387 125.219 141.563 123.767 143.394 121.934L143.395 121.934L186.335 78.9552ZM59.3384 145.171C61.4169 143.092 62.5846 140.273 62.5846 137.334V85.8337C62.5846 82.8942 61.4169 80.0751 59.3384 77.9966C57.2599 75.918 54.4408 74.7503 51.5013 74.7503C48.5618 74.7503 45.7427 75.918 43.6642 77.9966C41.5857 80.0751 40.418 82.8942 40.418 85.8337V137.334C40.418 140.273 41.5857 143.092 43.6642 145.171C45.7427 147.249 48.5618 148.417 51.5013 148.417C54.4408 148.417 57.2599 147.249 59.3384 145.171Z"
                stroke="white"
                stroke-width="5"
                className="icon drawing-table-1"
            />
            <path
                d="M106.555 85.1376L163.428 28.2644C164.225 27.4664 165.172 26.8333 166.214 26.4014C167.256 25.9694 168.373 25.7471 169.501 25.7471C170.629 25.7471 171.745 25.9694 172.787 26.4014C173.829 26.8333 174.776 27.4664 175.573 28.2644L186.32 39.0108C187.118 39.8079 187.751 40.7546 188.183 41.7966C188.615 42.8386 188.837 43.9555 188.837 45.0835C188.837 46.2115 188.615 47.3284 188.183 48.3704C187.751 49.4124 187.118 50.359 186.32 51.1562L129.446 108.029H106.555V85.1376Z"
                fill="none"
                stroke="#C4D3FF"
                strokeWidth={50}
                className="icon drawing-table-2"
                clipPath="url(#drawing-table-2)"
            />
            <defs>
                <clipPath id="drawing-table-2">
                    <path
                        d="M106.555 85.1376L163.428 28.2644C164.225 27.4664 165.172 26.8333 166.214 26.4014C167.256 25.9694 168.373 25.7471 169.501 25.7471C170.629 25.7471 171.745 25.9694 172.787 26.4014C173.829 26.8333 174.776 27.4664 175.573 28.2644L186.32 39.0108C187.118 39.8079 187.751 40.7546 188.183 41.7966C188.615 42.8386 188.837 43.9555 188.837 45.0835C188.837 46.2115 188.615 47.3284 188.183 48.3704C187.751 49.4124 187.118 50.359 186.32 51.1562L129.446 108.029H106.555V85.1376Z"
                        fill="none"
                        stroke="#C4D3FF"
                        className="icon drawing-table-2"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DrawingTableIcon

import React from 'react'
function ProgressLine3() {
    return (
        <svg
            class="c-dashed-line"
            width="482"
            height="370"
            viewBox="0 0 482 410"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className='w-[200px] h-[178px] rotate-[24deg] sm:rotate-0 sm:h-[220px] sm:w-[280px] md:w-[350px] lg:h-auto lg:w-auto'
        >
            <path
                id="c-dashed-line"
                d="M62.0362 6.14246C-34.3399 111.621 -86.366 322.579 476.539 322.579"
            />
            <use class="c-dashed-line__path" href="#c-dashed-line" />
            <use class="c-dashed-line__dash" href="#c-dashed-line" />
        </svg>
    )
}

export default ProgressLine3
